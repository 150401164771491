import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ColorsPalette } from 'themes/colors';
import { TypographyCollection } from 'themes/typography';

export default makeStyles<Theme>((theme) => ({
  appBarMenu: {
    display: 'flex',
    paddingBottom: 8,
    paddingLeft: 14,
    paddingTop: 10,
  },
  element: {
    ...{
      '&:hover': {
        color: ColorsPalette.hoverActionCTA,
      },
      color: ColorsPalette.primary,
      cursor: 'pointer',
      textDecoration: 'none',
      whiteSpace: 'nowrap',

      [theme.breakpoints.up('md')]: {
        paddingRight: 36,
      },
      [theme.breakpoints.down('lg')]: {
        paddingRight: '0.7rem',
      },
    },
    ...TypographyCollection.link.bold.xs,
  },
  flag: {
    '-ms-interpolation-mode': 'nearest-neighbor',
    borderRadius: '50%',
    height: 12,
    imageRendering: 'pixelated',
    marginBottom: 2,
    marginRight: 9,
    width: 12
  },
  languageContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
  },
}));
