import classNames from 'classnames';
import React from 'react';
import { pushOrReloadRoute } from 'utils/routes';

import { PageTypes } from '@constants/pageTypes';
import { useTranslation } from '@hooks/useTranslation';
import { useAuthStore } from '@reducers/auth/useState';
import { usePageDataStore } from '@reducers/pageData/useState';

import loggedInUserMenuStyles from './styles';

interface ILoggedInUserMenuProps {
  state: boolean;
  backgroundColor: string;
}

const UserMenu: React.FunctionComponent<ILoggedInUserMenuProps> = ({ state, backgroundColor }) => {
  const classes = loggedInUserMenuStyles({
    backgroundColor,
  });
  const { onLogOut, user } = useAuthStore();
  const {
    data: { pages },
  } = usePageDataStore();
  const { t } = useTranslation();
  const menuItems = [
    {
      label: t('account.myOrders'),
      type: PageTypes.account_orders,
    },
    {
      label: t('account.myDetails'),
      type: PageTypes.account_details,
    },
    {
      label: t('account.linkedAccounts'),
      type: PageTypes.account_linkedAccounts,
    },
    {
      label: t('account.changePassword'),
      type: PageTypes.account_changePassword,
    },
    {
      label: t('account.myDeliveryAddresses'),
      type: PageTypes.account_delivery,
    },
    {
      label: t('account.myPaymentsMethods'),
      type: PageTypes.account_payment,
    },
    {
      label: t('account.notifications'),
      type: PageTypes.account_notifications,
    },
    // {
    //   type: null,
    // },
    // {
    //   label: t('account.helpAndFAQs'),
    //   type: PageTypes.account_help,
    // },
  ];

  const menuItemData = (type: PageTypes) => {
    return pages?.find((page) => page.type === type);
  };

  return (
    <div
      id="logged-in-user-menu"
      className={classNames(classes.loggedInUserMenuContainer, { [classes.loggedInUserMenuContainerActive]: state })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={classes.menuWelcome}>
        {t('account.hi')} {user?.data?.attributes['first-name']}
      </div>
      {menuItems.map((menuItem, index) => {
        if (menuItem.type) {
          const pageItemData = menuItemData(menuItem.type);
          return pageItemData ? (
            <span
              className={classes.menuLink}
              key={`menu-link-${index}`}
              onClick={() => {
                pushOrReloadRoute(pageItemData?.url);
              }}
            >
              {menuItem.label}
            </span>
          ) : null;
        } else {
          return <span key={`separator-${index}`} className={classes.separator} />;
        }
      })}
      <span key={`separator-last`} className={classes.separator} />
      <span key={`menu-link-last`} className={classes.menuLink} onClick={onLogOut}>
        <div className={classes.menuLinkA}>{t('common.log_out')}</div>
      </span>
    </div>
  );
};

export default UserMenu;
