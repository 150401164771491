import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { headerHeight } from '@constants/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';
import { ShadowsPalette } from '@themes/shadows';

export default makeStyles<Theme, { backgroundColor: string }>((theme: Theme) =>
  createStyles({
    loggedInUserMenuContainer: {
      '&$loggedInUserMenuContainerActive': {
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column',
        width: 'max-content',
      },
      background: ({ backgroundColor }) => backgroundColor,
      border: `3px solid ` + ColorsPalette.neutral2,
      borderRadius: '4px',
      boxShadow: ShadowsPalette.shadowS,
      cursor: 'default',
      display: 'none',
      minHeight: '374px',
      minWidth: '162px',
      padding: '24px 24px 14px 24px',
      position: 'absolute',
      top: `calc(${headerHeight}px - 28px)`,
      zIndex: 10,
    },
    loggedInUserMenuContainerActive: {},
    menuLink: {
      '& p': {
        margin: '0',
      },
      '&:hover': {
        color: ColorsPalette.hoverActionCTA,
      },
      color: ColorsPalette.primary,
      cursor: 'pointer',
      display: 'flex',
      fontFamily: FontCollection.raleway,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '160%',
      marginBottom: '5px',
      marginLeft: '0',
      marginTop: '5px',
    },
    menuLinkA: {
      '&:hover': {
        color: ColorsPalette.hoverActionCTA,
      },
      color: ColorsPalette.primary,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    menuWelcome: {
      display: 'flex',
      flexDirection: 'row',
      fontFamily: FontCollection.inter,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '124%',
      marginBottom: '10px',
    },
    separator: {
      border: `1px solid ` + ColorsPalette.neutral2,
      marginBottom: '3px',
      marginTop: '3px',
      width: '100%',
    },
  }),
);
