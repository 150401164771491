import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acSearchPanelClose } from '@actions/acSearch';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

import useStyles from './styles';

const SearchInputOverlay: React.FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch<TThunkDispatch>();
  const isOpenPanel = useSelector<IStore, boolean>((state) => state.search.isOpenPanel);
  const closeSearchPanel = () => dispatch(acSearchPanelClose());
  const [isMountedOverlay, setIsMountedOverlay] = useState(false);

  const handleClick = () => {
    if (isMountedOverlay && isOpenPanel) {
      closeSearchPanel();
    }
  };

  useEffect(() => {
    setIsMountedOverlay(true);
    return () => {
      setIsMountedOverlay(false);
    };
  }, []);

  return <>{isOpenPanel ? <div className={classes.overlay} onClick={handleClick} /> : null}</>;
};

export default SearchInputOverlay;
