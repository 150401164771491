import React from 'react';

import SearchInputWithButton from '@components/layout/header/AppBar/Search/SearchInput/SearchInputContainer';
import SearchInfo from './SearchInfo/SearchInfo';
import SearchInputOverlay from './SearchInputOverlay/SearchInputOverlay';
import useStyles from './styles';

const Search: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <SearchInputOverlay />
      <SearchInputWithButton />
      <SearchInfo />
    </div>
  );
};

export default Search;
