import { useDispatch, useSelector } from 'react-redux';

import { IGetForgotLinkSuccessPayload } from '@interfaces/auth/actions';
import { IAuthReducer } from '@interfaces/auth/reducer';
import { IApiResponse, TThunkDispatch } from '@interfaces/index';
import { IPageDataReducer } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';

import {
  acAccountLogout,
  acCustomerGetProfile,
  acForgotPassword,
  acRegisterCustomer
} from '@actions/acAuth';

export interface IUseAuthStore extends IAuthReducer {
  onGetCustomer: () => void;
  onGetForgotLink: (email: string, path: string) => Promise<IApiResponse<IGetForgotLinkSuccessPayload> | null>;
  onLogOut: () => void;
  onRegisterCustomer: (params: any) => Promise<IApiResponse<any> | null>;
}

export const useAuthStore: () => IUseAuthStore = () => {
  const authReducer: IAuthReducer = useSelector((state: IStore) => state.auth);
  const pageDataReducer: IPageDataReducer = useSelector((state: IStore) => state.pageData);
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const locale = { country: pageDataReducer.countryCode, lang: pageDataReducer.languageCode };
  const result: IUseAuthStore = {
    ...authReducer,
    onGetCustomer: () => dispatch(acCustomerGetProfile()),
    onGetForgotLink: (email, path) => dispatch(acForgotPassword({ email, path }, locale)),
    onLogOut: () => dispatch(acAccountLogout()),
    onRegisterCustomer: (params) => dispatch(acRegisterCustomer(params)),
  };
  return result;
};
