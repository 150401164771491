import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acPageCountrySelectAction } from '@actions/acPage';
import Link from '@components/common/Link';
import { imageUlrSuffix } from '@constants/urls';
import { useTranslation } from '@hooks/useTranslation';
import { TThunkDispatch } from '@interfaces/index';
import { ICountrySelectItem, ILanguageSelectItem, IPageData } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';

import appBarMenuStyles from './styles';

const AppBarMenu: React.FunctionComponent<{}> = () => {
  const classes = appBarMenuStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch<TThunkDispatch>();
  const onCountrySelectHandle = useCallback((state) => dispatch(acPageCountrySelectAction(state)), [dispatch]);
  const countryCode = useSelector<IStore, string | null>((state) => state.pageData.countryCode);
  const languageCode = useSelector<IStore, string | null>((state) => state.pageData.languageCode);
  const pageData = useSelector<IStore, IPageData>((state) => state.pageData.data);
  const topMenu = pageData['top-menu'] || [];

  const countries = pageData['country-select'];

  const currentCountry = (): ICountrySelectItem | null => {
    if (countries) {
      const foundCountry = countries.find((country) => country.code === countryCode);
      return foundCountry || null;
    }
    return null;
  };

  const chosenCountry = currentCountry();

  const currentLanguage = (): ILanguageSelectItem | null => {
    if (countries) {
      const foundLanguage = chosenCountry?.languages.find((language) => language.code === languageCode);
      return foundLanguage || null;
    }
    return null;
  };

  const chosenLanguage = currentLanguage();

  return (
    <div className={classes.appBarMenu}>
      <span
        id="open-language-select-modal"
        className={classNames(classes.element, classes.languageContainer)}
        onClick={() => {
          onCountrySelectHandle(true);
        }}
      >
        {chosenLanguage ? (
          <>
            {chosenCountry && (
              <img
                src={imageUlrSuffix + chosenCountry.flag}
                className={classes.flag}
                alt="Language flag"
                width={12.8}
                height={12.8}
              />
            )}
            {chosenLanguage.code.toUpperCase()}
          </>
        ) : (
          <>{t('common.select_language')}</>
        )}
      </span>

      {topMenu.filter((item) => !!item).map(({ url, translation }) => (
        <Link legacyBehavior href="/[...params]" as={url} key={translation}>
          <a className={classes.element}>
            {translation}
          </a>
        </Link>
      ))}
    </div>
  );
};

export default AppBarMenu;
