import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) => ({
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 3,
  },
}));
